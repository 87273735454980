const Spike5 = ({ className }: { className?: string }) => (
  <div className={className}>
    <svg
      width="87"
      height="96"
      viewBox="0 0 87 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5203 56.3693C79.3737 57.757 81.0476 59.4502 82.4687 61.4193L82.4689 61.4196C90.0207 71.8916 87.6695 86.4872 77.2209 94.0236C76.7207 94.3848 76.2225 94.7168 75.7276 95.0284L74.5473 93.1538C75.0092 92.863 75.4678 92.5571 75.9244 92.2273L75.925 92.2269C85.3781 85.4086 87.5106 72.1986 80.6724 62.7157M77.5203 56.3693L76.1926 58.1426L77.5203 56.3693ZM76.1926 58.1426C77.8658 59.3954 79.3818 60.9275 80.6721 62.7153L76.1926 58.1426Z"
        fill="#010101"
      />
      <path
        d="M44.4164 95.0924H9.94715C5.51665 95.0924 1.90137 91.4683 1.90137 87.0466V86.2935C1.90137 81.863 5.52551 78.2477 9.94715 78.2477H44.4164C48.8469 78.2477 52.4622 81.8718 52.4622 86.2935V87.0466C52.4622 91.4683 48.838 95.0924 44.4164 95.0924Z"
        fill="#76CFE7"
      />
      <path
        d="M40.7834 93.6304L11.9054 74.8185C8.19267 72.3994 7.13821 67.3929 9.55726 63.6802L9.97373 63.0422C12.3928 59.3294 17.3992 58.275 21.112 60.694L49.99 79.5059C53.7027 81.925 54.7572 86.9314 52.3381 90.6442L51.9217 91.2822C49.5026 94.9861 44.4962 96.0494 40.7834 93.6304Z"
        fill="#76CFE7"
      />
      <path
        d="M27.3324 77.8755H17.5233C12.3307 77.8755 8.0863 73.6311 8.0863 68.4385V36.9908C8.0863 31.7983 12.3307 27.5539 17.5233 27.5539H27.3324C32.5249 27.5539 36.7693 31.7983 36.7693 36.9908V68.4385C36.7693 89.8289 32.5249 77.8755 27.3324 77.8755Z"
        fill="#76CFE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3282 79.3464C6.30562 79.3464 3.00901 82.643 3.00901 86.6656C3.00901 90.6882 6.30562 93.9848 10.3282 93.9848H48.6077V96.2H10.3282C5.08217 96.2 0.793762 91.9116 0.793762 86.6656C0.793762 81.4196 5.08217 77.1312 10.3282 77.1312H29.5122V79.3464H10.3282Z"
        fill="#010101"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4943 66.4802V39.3745H24.7096V66.4802C24.7096 70.0704 21.7757 72.9931 18.1967 72.9931C14.6065 72.9931 11.6839 70.0592 11.6839 66.4802V54.1634H13.8991V66.4802C13.8991 68.8381 15.8323 70.7778 18.1967 70.7778C20.5546 70.7778 22.4943 68.8446 22.4943 66.4802Z"
        fill="#010101"
      />
      <path
        d="M22.4323 28.5375C30.2575 28.5375 36.601 22.1939 36.601 14.3687C36.601 6.54357 30.2575 0.200012 22.4323 0.200012C14.6071 0.200012 8.26355 6.54357 8.26355 14.3687C8.26355 22.1939 14.6071 28.5375 22.4323 28.5375Z"
        fill="#76CFE7"
      />
      <path
        d="M60.0206 62.1295C58.3813 62.1295 56.7952 62.3245 55.2711 62.6966H57.3269V95.0924H75.7312C78.549 91.6189 80.2414 87.1884 80.2414 82.3592C80.2503 71.1855 71.1943 62.1295 60.0206 62.1295Z"
        fill="#76CFE7"
      />
      <path
        d="M57.2471 95.0924H54.7483C48.9 95.0924 44.1151 90.3075 44.1151 84.4592V74.464C44.1151 68.6157 48.9 63.8308 54.7483 63.8308H57.2471C63.0954 63.8308 67.8803 68.6157 67.8803 74.464V84.4592C67.8803 90.3075 63.0954 95.0924 57.2471 95.0924Z"
        fill="#76CFE7"
      />
      <path
        d="M53.047 43.1138C53.0204 43.0518 52.9939 42.9986 52.9584 42.9455L51.5584 40.5175L50.1583 38.0896C49.5647 37.0618 48.0849 37.0618 47.4912 38.0896L44.5405 43.867V44.0974V54.9167C44.5405 60.9244 49.4583 65.8334 55.4572 65.8334H56.3522C62.3599 65.8334 67.2689 60.9155 67.2689 54.9167L67.2423 43.867L64.2473 38.0896C63.6536 37.0618 62.1738 37.0618 61.5802 38.0896L60.1801 40.5175L58.7801 42.9455C58.7446 42.9986 58.7181 43.0606 58.6915 43.1138"
        fill="#76CFE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5152 76.954V91.2999C45.5152 92.7705 46.7294 93.9848 48.2001 93.9848C49.6707 93.9848 50.8849 92.7705 50.8849 91.2999V82.7136H53.1002V91.2999C53.1002 93.994 50.8941 96.2001 48.2001 96.2001C45.506 96.2001 43.2999 93.994 43.2999 91.2999V76.954H45.5152Z"
        fill="#010101"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.9119 91.291V76.2539H67.1271V91.291C67.1271 93.9851 64.921 96.1911 62.227 96.1911C59.5329 96.1911 57.3268 93.9851 57.3268 91.291V82.7047H59.5421V91.291C59.5421 92.7616 60.7564 93.9759 62.227 93.9759C63.6976 93.9759 64.9119 92.7616 64.9119 91.291Z"
        fill="#010101"
      />
    </svg>
  </div>
);

export default Spike5;
