import * as React from "react";
import { cn } from "@/app/_utils/ui";

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-2xl border border-neutral-300 bg-white transition-all ease-in-out duration-500",
      className,
    )}
    {...props}
  />
));
Card.displayName = "Card";

export { Card };
