"use client";
import React from "react";
import HomeCard from "./HomeCard";
import { useSelector } from "react-redux";
import { DailyArticle, Articles } from "@/app/_types";
import { RootState } from "../../reducers/store";
import { readingListCompletionDeep } from "@/app/_utils/article";
import { randomNumber } from "@/app/_utils/helper";
import Image from "next/image";
import Spike5Graphic from "../../ui/graphics/Spike5";
import Spike6Graphic from "../../ui/graphics/Spike6";

const HomeCards = () => {
  const readingLists = useSelector((state: RootState) => state.readingLists);
  const userReadArticles = useSelector(
    (state: RootState) => state.userReadArticles,
  );
  const readingListData = readingLists.readingLists["manifestobychapter"];
  const { userReadCount, allArticlesCount } = readingListCompletionDeep(
    readingListData,
    userReadArticles.articles,
  );

  const articles: Articles = useSelector((state: RootState) => state.articles);
  const dailyArticle: DailyArticle = useSelector(
    (state: RootState) => state.dailyArticle,
  );
  let articleId = dailyArticle.article.id;
  let article = articles.articles[articleId];

  if (!article) {
    const keys = Object.keys(articles.articles);
    const excludeKeys = keys.filter((key: string) => {
      if (!articles.articles[key].status) {
        // if there is no status data, do not exclude
        return false;
      } else {
        return (
          articles.articles[key].status?.vault ||
          !articles.articles[key].status.published
        );
      }
    });
    const filteredKeys = keys.filter((key) => !excludeKeys.includes(key));
    const randomArticleIndex = randomNumber(filteredKeys.length - 1);
    articleId = filteredKeys[randomArticleIndex];
    article = articles.articles[randomArticleIndex];
  }

  const month = new Date().getMonth();

  const vegx =
    month === 8
      ? {
          subtitle: "Spike 5",
          href: "/reading-list/vegx-september",
          icon: <Spike5Graphic className="scale-[.85] h-20 relative -top-2" />,
        }
      : {
          subtitle: "Spike 6",
          href: "/reading-list/vegx-october",
          icon: <Spike6Graphic className="scale-[.85] h-20 relative -top-2" />,
        };

  return (
    <div
      id="homeCards"
      className="grid lg:grid-cols-3 gap-6 py-10 px-5 sm:px-10 pb-5 sm:pb-10"
    >
      <HomeCard
        graphic={
          <Image
            src="/assets/images/icons/home/manifesto.svg"
            width={53}
            height={80}
            alt=""
          />
        }
        href="/reading-list/manifestobychapter"
        title="MANIFESTO"
        subtitle={
          readingListData
            ? `${userReadCount} out of ${allArticlesCount} articles read`
            : `\u00A0`
        }
        buttonText="Dive In!"
      />
      <HomeCard
        graphic={
          <Image
            src="/assets/images/icons/home/article.svg"
            width={80}
            height={80}
            alt=""
          />
        }
        href={`/reading-list/manifestobychapter/article/${articleId}?chapterDepth=0&isDailyArticle=true`}
        title="DAILY ARTICLE"
        subtitle={article?.title || ""}
        buttonText="Don’t Miss It!"
      />
      <HomeCard
        graphic={vegx.icon}
        href={vegx.href}
        title={
          <>
            <span className="font-bold">VEGX MONTHLY</span>
          </>
        }
        subtitle={vegx.subtitle}
        buttonText="Check It Out!"
      />
    </div>
  );
};

export default HomeCards;
