"use client";
import { cn } from "@/app/_utils/ui";
import { CloseOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import useSidebarCollapse from "../../hooks/useSidebarCollapse";

export default function HomePreviewAlert() {
  const [isVisible, setIsVisible] = useState(false);
  const isSidebarCollapsed = useSidebarCollapse();

  useEffect(() => {
    const isAlertClosed = localStorage.getItem("homePreviewAlertClosed");
    const currentYear = new Date().getFullYear();
    if (currentYear === 2024 && !isAlertClosed) {
      setIsVisible(true); // Show the alert if not previously closed and the year is 2024
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("homePreviewAlertClosed", "true");
  };

  if (!isVisible) return null;

  return (
    <div
      className={cn(
        "absolute left-0 top-[56px] sm:top-[430px] z-50 transition-all ease-in-out duration-500 w-full sm:w-auto",
        isSidebarCollapsed ? "sm:left-8 xl:left-[94px]" : "sm:left-[280px]",
      )}
      style={{
        boxShadow:
          "0px 3px 6px -4px #0000001F, 0px 6px 16px 0px #00000014, 0px 9px 28px 8px #0000000D",
      }}
    >
      <div className="relative w-full sm:max-w-2xl sm:rounded-lg bg-white shadow-lg">
        <div
          className="absolute -top-3 left-3 sm:-left-3 sm:top-8 h-6 w-6 rotate-45 transform bg-white"
          style={{
            boxShadow:
              "0px 3px 6px -4px #0000001F, 0px 6px 16px 0px #00000014, 0px 9px 28px 8px #0000000D",
          }}
        ></div>
        <div className="bg-white relative z-10 p-6  sm:rounded-lg">
          <button
            onClick={handleClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close"
          >
            <CloseOutlined />
          </button>
          <h2 className="mb-4 text-base font-bold">Preview Daily Articles!</h2>
          <p className="text-sm w-full max-w-[320px] pb-4">
            Hey VEGgies! 👋 Want to see what’s coming up?
            <br />
            Head to the News section for a preview of daily articles. 📚 Plan
            your huddles and stay on top!
          </p>
        </div>
      </div>
    </div>
  );
}
